import React, { useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import Fuse from "fuse.js";
import AddButton from "./AddButton";
import CardFormModal from "./CardFormModal";
import CardItem from "./CardItem";
import { addCard } from "./store";
import { useDispatch } from "react-redux";
import nlp from "compromise";
import Tag from "./Tag";

const options = {
  // Define the list of words to search in
  keys: ["name"], // Specify the property/key to match against

  // Configure the matching options
  includeScore: true, // Include similarity score in the result
  threshold: 0.5, // Adjust the threshold for considering a match
};

const cardTags = [
  // {
  //   name: "name#",
  //   used: false,
  // },
  {
    name: "business#",
    used: false,
  },
  {
    name: "amount#",
    used: false,
  },
  {
    name: "email#",
    used: false,
  },
  {
    name: "password#",
    used: false,
  },
  {
    name: "code#",
    used: false,
  },
  {
    name: "expires#",
    used: false,
  },
  {
    name: "starts#",
    used: false,
  },
  {
    name: "yearly#",
    used: false,
    bool: true,
  },
];

const wordList = [
  { name: "expirationDate" },
  { name: "startDate" },
  { name: "yearly" },
  { name: "active" },
  { name: "password" },
  { name: "email" },
  { name: "amount" },
  { name: "business" },
  { name: "code" },
  { name: "name" },
];

const SearchBar = () => {
  const inputRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [numberOfCards, setNumberOfCards] = useState(1);
  const [tags, setTags] = useState(() => cardTags);
  const [card, setCard] = useState({});
  const fuse = new Fuse(wordList, options);
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    setNumberOfCards(1); // TODO
    const inputText = e.target.value;
    setSearchText(inputText);
    parseInputs();
  };

  function extractInfo() {
    const doc = nlp(searchText);
    // Extract the expiration date
    // const expirationDate = doc
    //   .match("expires on [^,]+")
    //   .out("text")
    //   .replace("expires on ", "");
    const expiresMatch = doc.match(/expires on ([a-zA-Z]+\s\d{1,2},\s\d{4})/);
    const expirationDate = expiresMatch ? expiresMatch[1] : null;

    // Extract the amount
    const amount = doc.values().toNumber().out("text");

    // Extract the email
    const email = doc.match("#Email").out("text");
    console.log({ expirationDate, amount, email });
  }

  const parseInputs = () => {
    // extractInfo();
    const splitText = searchText.split(/(?![^(]*\))(?=\s)/);
    if (splitText.length === 0) setCard({});
    const newCard = {};

    splitText.map((word, _index) => {
      if (word.includes("#")) {
        const [identifier, content] = word.split("#");
        const result = fuse.search(identifier);
        const first = result[0];
        if (!first) return word;
        const closestMatch = first.item.name;
        newCard[closestMatch] = content.replace(/\(/g, "").replace(/\)/g, "");
        return word;
      }
      return word;
    });
    newCard.active = true;
    setCard({ ...card, ...newCard });
  };

  const handleAdd = () => {
    dispatch(addCard({ card }));
    setCard({});
    setSearchText("");
  };

  const renderText = () => {
    searchText.split(/(?![^(]*\))(?=\s)/).map((word, index) => {
      return renderParsedSentence(word, index);
    });
  };

  // const highlightedText = highlightSearchText(searchText);
  const renderParsedSentence = (word, index) => {
    if (word.includes("#")) {
      const [identifier, content] = word.split("#");
      const result = fuse.search(identifier);
      const first = result[0];
      // let closestMatch = identifier;
      if (first) {
        // closestMatch = first.item.name;
        // setCard({ ...card, [closestMatch]: content.replace(/[()]/g, "") });
      }
      return (
        <>
          <span key={index + identifier} style={{ color: "blue" }}>
            {identifier + " "}
          </span>
          <span
            key={index + content}
            style={{ fontWeight: 600, color: "black" }}
          >
            {content + " "}
          </span>
        </>
      );
    } else {
      return (
        <span style={{ color: "black" }} key={index}>
          {word + " "}
        </span>
      );
    }
  };

  const moveCursorBack = () => {
    const inputElement = inputRef.current;
    console.log({ inputElement });
    if (inputElement && inputElement.selectionStart !== undefined) {
      let pos = inputElement.selectionStart;
      inputElement.selectionStart = pos - 1;
      inputElement.selectionEnd = pos - 1;
      inputElement.focus();
    }
  };

  const clickedTag = (tag) => {
    const newUsed = !tag.used;
    if (newUsed) {
      setSearchText(searchText + ` ${tag.name}()`);
    } else {
      setSearchText(searchText.replace(` ${tag.name}()`, ""));
    }
    setTags(
      tags.map((t) => {
        if (tag.name === t.name) {
          return { ...t, used: newUsed };
        }
        return t;
      })
    );
    setTimeout(() => {
      moveCursorBack();
    }, 200);
  };

  return (
    <Form>
      <Form.Group controlId="searchBar" style={{ marginTop: 20 }}>
        <Form.Control
          type="text"
          placeholder="Add a card (e.g. expires#(08/18/23) for business#(Juice Generation) with amount#400 and email#jon@jmail.com and password#1234567 and code#32ewdfffdf"
          value={searchText}
          onChange={handleInputChange}
          ref={inputRef}
        />

        {tags.map((tagItem, index) => {
          return (
            <Tag
              key={index}
              text={tagItem.name}
              onClick={() => clickedTag(tagItem)}
              disabled={tagItem.used}
            />
          );
        })}
        {renderText()}
      </Form.Group>
      {searchText && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>{numberOfCards}</div>
          {Array(numberOfCards)
            .fill("")
            .map((_n, index) => {
              return <CardItem key={index} card={card} />;
            })}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <AddButton onButtonClick={handleAdd} />
            <br />
            <CardFormModal card={card} />
          </div>
        </div>
      )}
    </Form>
  );
};

export default SearchBar;
