import React from "react";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import "./Toggle.scss"; // Import custom CSS styles

const ToggleComponent = ({ isActive = true, onToggle = () => {} }) => {
  const handleToggle = () => {
    onToggle(!isActive);
  };

  return (
    <ToggleButtonGroup type="checkbox" className="toggle-container">
      <ToggleButton
        className={isActive ? "toggle-button active" : "toggle-button"}
        onClick={handleToggle}
      >
        <span className="toggle-slider" />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleComponent;
