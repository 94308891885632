import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./App.scss";
import Droppable from "./Droppable";
import DropzoneUI from "./DropzoneUI";
import CardItem from "./CardItem";
import { removeCard, addCardToWallet, updateCard } from "./store";

const CardWallet = () => {
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState(null);
  const cards = useSelector((state) => state.cardStore.cards);
  const walletCardIDs = useSelector((state) => state.walletStore.cardIDs || []);

  const handleToggle = (cardID, newValue) => {
    const updateAttributes = { active: newValue };
    dispatch(updateCard({ id: cardID, updateAttributes }));
  };

  const onDrop = ({ cardID }) => {
    dispatch(addCardToWallet({ id: cardID }));
    dispatch(removeCard({ id: cardID }));
  };

  const handleCardClick = (id) => {
    setSelectedId(id);
  };

  return (
    <>
      <div style={{ color: "black", fontSize: 20, padding: 10 }}>
        {" "}
        Today's Cards
      </div>
      <Droppable onDrop={onDrop}>
        <div
          style={{
            width: "100%",
            border: "1px solid lightgray",
            borderRadius: 4,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {walletCardIDs.length === 0 && <DropzoneUI />}
          {walletCardIDs.length > 0 &&
            walletCardIDs.map((cardID, index) => {
              const card = cards[cardID];
              return (
                <div
                  key={index}
                  className={`walletCard ${
                    selectedId === cardID ? "selected" : ""
                  }`}
                  onClick={() => {
                    handleCardClick(cardID);
                  }}
                >
                  <CardItem
                    draggable={false}
                    card={card}
                    handleToggle={handleToggle}
                  />
                </div>
              );
            })}
        </div>
      </Droppable>
    </>
  );
};

export default CardWallet;
