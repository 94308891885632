import React from "react";
import { Badge } from "react-bootstrap";
import "./App.scss";

const Tag = ({ text, variant = "primary", onClick, disabled = false }) => {
  return (
    <div className={`tag d-inline-block mr-2`} onClick={onClick}>
      <Badge pill className={`p-2 ${disabled ? "disabled" : ""}`}>
        {text}
      </Badge>
    </div>
  );
};

export default Tag;
