import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
const apiUrl = "http://localhost:3003";

export async function postCredential(credential) {
  const requestData = {
    credential,
  };
  try {
    const response = await axios.post(`${apiUrl}/authenticate`, requestData);
    const { data } = response;

    if (data.success === true) {
      return data;
    }
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
}

export async function postData(route, requestData, token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.post(
      `${apiUrl}/${route}`,
      requestData,
      config
    );
    const { data } = response;

    if (data.success === true) {
      console.log({ data });
      return data;
    }
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
}
