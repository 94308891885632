import React from "react";
import { useDrag } from "react-dnd";

const DraggableComponent = ({ cardID, draggable = true, children }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "component",
    item: { cardID },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const renderDraggable = () => {
    if (draggable) {
      return (
        <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
          {children}
        </div>
      );
    } else {
      return <div>{children}</div>;
    }
  };

  return <>{renderDraggable()}</>;
};

export default DraggableComponent;
