import moment from "moment";
import Draggable from "./Draggable";
import { Card } from "react-bootstrap";
import Toggle from "./Toggle";
import "./App.scss";

const CardItem = ({ card, handleToggle, draggable = true }) => {
  const momentDate = (dateString) => {
    const futureDate = moment(dateString);
    const currentDate = moment();
    const duration = moment.duration(futureDate.diff(currentDate));
    return `${Math.floor(duration.asDays())} days left`;
  };

  return (
    <Draggable cardID={card.id} draggable={draggable}>
      <Card
        className={`cardItem hover-shadow ${!card.active ? "disabled" : ""}`}
        style={{
          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
          borderRadius: "8px",
          margin: "10px",
          backgroundColor: "#f9f9f9",
          color: "#1f2568",
          fontSize: 12,
          width: 280,
          alignItems: "left",
        }}
      >
        <Card.Body
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              position: "relative",
              justifyContent: "space-between",
            }}
          >
            {/* <div className="cardIcon" style={{}}>
              <img src="crown.png" width={50} />
            </div> */}
            <div
              className="cardIcon"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Toggle
                isActive={card.active}
                onToggle={(newValue) => handleToggle(card.id, newValue)}
              />
              <div className="expiration">
                {momentDate(card.expirationDate)}
              </div>
            </div>
          </div>
          <div
            style={{
              fontSize: 12,
              textAlign: "left",
            }}
          >
            <div className="middleCard">
              <Card.Title className="title">{card.business}</Card.Title>
              {/* <div className="business">{card.business}</div> */}
              <div className="amount">${card.amount}</div>
            </div>
            <div className="bottomCard">
              <div style={{ textAlign: "left" }}>
                <div className="email">{card.email}</div>
                <div className="password">{card.password}</div>
              </div>
              {card.code && <div className="code">{card.code}</div>}
            </div>
          </div>
        </Card.Body>
      </Card>
    </Draggable>
  );
};

export default CardItem;
