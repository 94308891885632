import "./App.scss";
import CardWallet from "./CardWallet";
import CardPage from "./CardPage";
import Header from "./Header";
import SearchBar from "./SearchBar";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import store from "./store";

function App() {
  const GOOGLE_CLIENT =
    "25990712666-52vi7ofjgtj8vqphkk9i5gv500oogo7u.apps.googleusercontent.com";
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getBackend = () => {
    return isMobile ? TouchBackend : HTML5Backend;
  };
  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT}>
        <DndProvider
          backend={getBackend()}
          options={{
            enableMouseEvents: true,
            preventDefaultTouchmoveEvent: isMobile ? true : false,
          }}
        >
          <div className="App">
            <div
              className="logo"
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
                alignItems: "center",
                // padding: 20,
              }}
            >
              <div className="header-item">
                <img
                  alt=""
                  src="logo.png"
                  width={250}
                  style={{
                    position: "relative",
                    // transform: "rotate(-25deg)",
                    // marginTop: -20,
                    // marginLeft: -50,
                  }}
                />
                {/* Dis<span className="cardmiddle">card</span>om */}
              </div>
              <div
                className="header-item"
                style={{
                  paddingBottom: 20,
                }}
              >
                <Header />
              </div>
            </div>
            <div className="container">
              {/* <h1 style={{ textAlign: "left" }}>Your Cards</h1> */}
              <header className="App-header">
                <SearchBar />
                <CardWallet />
                <CardPage />
              </header>
            </div>
          </div>
        </DndProvider>
      </GoogleOAuthProvider>
    </Provider>
  );
}

export default App;
