import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
const CardFormModal = (card = {}) => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    // name: "",
    amount: "",
    startDate: null,
    expirationDate: null,
    yearly: "",
    code: "",
    email: "",
    password: "",
    business: "",
  });

  const processedCardFormData = () => {
    const cardClone = { ...card.card };
    if (cardClone.amount) {
      cardClone.amount = Number(cardClone.amount);
    }
    if (cardClone.expirationDate) {
      // cardClone.expirationDate = null;
      cardClone.expirationDate = new Date(cardClone.expirationDate);
    }
    if (cardClone.startDate) {
      cardClone.startDate = new Date(cardClone.startDate);
    } else {
      cardClone.startDate = null;
    }
    if (cardClone.yearly) {
      cardClone.yearly = !!cardClone.yearly;
    }
    return cardClone;
  };

  useEffect(() => {
    setFormData({ ...processedCardFormData() });
  }, [card]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDateChange = (date, field) => {
    setFormData((prevData) => ({ ...prevData, [field]: date }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form submission logic here
    // Reset form data
    setFormData({
      // id: "",
      // name: "",
      amount: "",
      startDate: null,
      expirationDate: null,
      yearly: "",
      code: "",
      email: "",
      password: "",
      business: "",
    });
    // Close the modal
    setShowModal(false);
  };

  return (
    <>
      {/* Button to open the modal */}
      <Button
        variant="primary"
        onClick={() => setShowModal(true)}
        style={{ maxWidth: 150 }}
      >
        Open Form
      </Button>

      {/* Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modal Title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {/* <Form.Group controlId="formId">
              <Form.Label>ID</Form.Label>
              <Form.Control
                type="text"
                name="id"
                value={formData.id}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </Form.Group> */}

            <Form.Group controlId="formAmount">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="text"
                name="amount"
                value={formData.amount}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formStartDate">
              <Form.Label>Start Date</Form.Label>
              <br />
              <DatePicker
                selected={formData.startDate}
                onChange={(date) => handleDateChange(date, "startDate")}
                dateFormat="MM/dd/yyyy"
                className="form-control"
              />
            </Form.Group>

            <Form.Group controlId="formExpirationDate">
              <Form.Label>Expiration Date</Form.Label>
              <br />
              <DatePicker
                selected={formData.expirationDate}
                onChange={(date) => handleDateChange(date, "expirationDate")}
                dateFormat="MM/dd/yyyy"
                className="form-control"
              />
            </Form.Group>

            <Form.Group controlId="formYearly">
              <Form.Check
                type="checkbox"
                label="Yearly"
                name="yearly"
                checked={formData.yearly}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formCode">
              <Form.Label>Code</Form.Label>
              <Form.Control
                type="text"
                name="code"
                value={formData.code}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                isInvalid={
                  formData.email && !/^\S+@\S+\.\S+$/.test(formData.email)
                }
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email address.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                isInvalid={formData.password && formData.password.length > 7}
              />
              <Form.Control.Feedback type="invalid">
                Password must be at least 8 characters long.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formLocation">
              <Form.Label>Business</Form.Label>
              <Form.Control
                type="text"
                name="business"
                value={formData.business}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CardFormModal;
