import { configureStore, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const businessNames = [
  "Stellar Solutions",
  "Fusion Foods",
  "Swift Boutique",
  "Pixel Perfect Web Design",
  "Harmony Spa & Wellness",
  "Green Thumb Landscaping",
  "Tech Innovators",
  "Serene Retreat Yoga Studio",
  "The Gourmet Pantry",
  "Creative Minds Advertising Agency",
];

function generateRandomFutureDate() {
  const currentDate = new Date(); // Get the current date
  const randomOffset = Math.floor(Math.random() * 365) + 1; // Generate a random offset between 1 and 365 days
  const futureDate = new Date(
    currentDate.getTime() + randomOffset * 24 * 60 * 60 * 1000
  ); // Add the offset to the current date

  return futureDate
    .toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/\//g, "/");
}
const dummyCards = Array(10)
  .fill({})
  .map((item, index) => {
    return {
      id: String(Math.random() * 1000),
      name: `Card ${index + 1}`,
      amount: Math.floor(Math.random() * 100) + 5,
      startDate: generateRandomFutureDate(),
      expirationDate: generateRandomFutureDate(),
      active: true,
      yearly: true,
      code: "ABC123",
      email: "example@example.com",
      password: "password1",
      business: businessNames[index],
    };
  });

const dummyCardsMap = dummyCards.reduce((accum, current) => {
  accum[current.id] = current;
  return accum;
}, {});

const dummyCardIDs = Object.keys(dummyCardsMap);

const initialCardsState = {
  cards: dummyCardsMap, // Your array of objects
  activeCardIDs: dummyCardIDs,
};

const initialWalletState = {
  cardIDs: [],
};

const initialUserState = {
  user: {},
};

const userSlice = createSlice({
  name: "userStore",
  initialState: initialUserState,
  reducers: {
    addCredential: (state, action) => {
      // Add to cards and active
      const { payload } = action;
      state.user.credential = payload;
    },
    addJWT: (state, action) => {
      const { payload } = action;
      Cookies.set("cardlark_token", payload, { expires: 180 });
      state.user.jwt = payload;
    },
    removeJWT: (state, _action) => {
      state.user.jwt = null;
      Cookies.remove("cardlark_token");
    },
  },
});

const cardsSlice = createSlice({
  name: "cardStore",
  initialState: initialCardsState,
  reducers: {
    addCard: (state, action) => {
      // Add to cards and active
      const { payload } = action;
      const { card } = payload;
      card.id = String(Math.random() * 1000);
      state.cards[card.id] = card;
      if (card.active) {
        state.activeCardIDs.push(card.id);
      }
    },
    deleteCard: (state, action) => {
      // Remove from cards and active?
      const { payload } = action;
      const newCards = state.cards.filter(
        (card) => card.id !== payload.card.id
      );
      state.cards = newCards;
    },
    removeCard: (state, action) => {
      // Remove from cards and active?
      const { payload } = action;
      const newCardIDs = state.activeCardIDs.filter((id) => id !== payload.id);
      state.activeCardIDs = newCardIDs;
    },
    updateCard: (state, action) => {
      const { payload } = action;
      state.cards[payload.id] = {
        ...state.cards[payload.id],
        ...payload.updateAttributes,
      };
    },
  },
});

const walletCardsSlice = createSlice({
  name: "walletStore",
  initialState: initialWalletState,
  reducers: {
    addCardToWallet: (state, action) => {
      // Update active and wallet ids, remove from active, to wallet
      const { payload } = action;
      state.cardIDs.push(payload.id);
    },
    removeCardFromWallet: (state, action) => {
      // remove id from wallet and to active
      const { payload } = action;
      const newWalletCardIDs = state.cardIDs.filter(
        (id) => id !== payload.card.id
      );
      state.cardIDs = newWalletCardIDs;
    },
    // Add more reducer functions for other actions if needed
  },
});

const store = configureStore({
  reducer: {
    cardStore: cardsSlice.reducer,
    walletStore: walletCardsSlice.reducer,
    userStore: userSlice.reducer,
  },
});

export const { addCredential, addJWT, removeJWT } = userSlice.actions;
export const { addCard, removeCard, updateCard } = cardsSlice.actions;
export const { addCardToWallet, removeCardFromWallet } =
  walletCardsSlice.actions;

export default store;
