import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import { GoogleLogin } from "@react-oauth/google";
import { addCredential, addJWT } from "./store";
import { postCredential } from "./services/dataFetcher.js";

const Header = () => {
  const user = useSelector((state) => state.userStore.user);
  const dispatch = useDispatch();
  const [loginCredential, setLoginCredential] = useState(null);

  useEffect(() => {
    const token = Cookies.get("cardlark_token");
    if (token) {
      dispatch(addJWT(token));
    }
  }, [dispatch]);

  const onSuccess = async (credentialResponse) => {
    const { credential } = credentialResponse;
    const data = await postCredential(credential);
    if (data.success === true) {
      setLoginCredential(credential);
      dispatch(addCredential(credential));
      dispatch(addJWT(data.token));
    }
  };
  const onError = () => {
    console.log("Login Failed");
  };

  const previousLogin = () => {
    return user.credential || user.jwt;
  };
  return (
    <>
      {!previousLogin() && (
        <GoogleLogin onSuccess={onSuccess} onError={onError} />
      )}
      {previousLogin() && (
        <div
          style={{
            border: "1px solid gray",
            borderRadius: 4,
            padding: 4,
            fontSize: 20,
          }}
        >
          Profile
        </div>
      )}
    </>
  );
};

export default Header;
