import React from "react";
import { useSelector, useDispatch } from "react-redux";
import "./App.scss";
import CardItem from "./CardItem";
import { addCard, removeCard, updateCard } from "./store";
import { postData } from "./services/dataFetcher.js";

const CardComponent = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userStore.user);
  const cards = useSelector((state) => state.cardStore.cards);
  const activeIDs = useSelector((state) => state.cardStore.activeCardIDs);

  // Dispatch an action to add an item
  const handleAddClick = (card) => {
    dispatch(addCard({ card }));
  };
  // Dispatch an action to add an item
  const handleRemoveClick = (card) => {
    dispatch(removeCard({ card }));
  };

  const handleToggle = (cardID, newValue) => {
    const card = cards[cardID];
    postData("cards", { card }, user.jwt);
    const updateAttributes = { active: newValue };
    dispatch(updateCard({ id: cardID, updateAttributes }));
  };

  return (
    <div
      style={{
        width: "100%",
        maxWidth: 1280,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
        alignContent: "center",
        alignItems: "center",
        alignSelf: "center",
      }}
    >
      {activeIDs.map((cardID, index) => {
        const card = cards[cardID];
        return <CardItem key={index} card={card} handleToggle={handleToggle} />;
      })}
    </div>
  );
};

export default CardComponent;
