import { FaPlus } from "react-icons/fa";
import { useState } from "react";
import "./Dropzone.scss"; // Import custom CSS for styling

const Dropzone = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleDragEnter = () => {
    setIsHovered(true);
  };

  const handleDragLeave = () => {
    setIsHovered(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsHovered(false);
    // Handle dropped files here
    // const files = event.dataTransfer.files;
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div
      className={`dropzone ${isHovered ? "active" : ""}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <FaPlus className="plus-icon" />
    </div>
  );
};

export default Dropzone;
