import React from "react";
import Button from "react-bootstrap/Button";
import { FaPlus } from "react-icons/fa";

const AddButton = ({ onButtonClick }) => {
  return (
    <Button
      variant="primary"
      onClick={onButtonClick}
      className="add-button"
      style={{ maxWidth: 150, justifyContent: "space-between" }}
    >
      <FaPlus className="add-icon" />
      Card
    </Button>
  );
};

export default AddButton;
